import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'infoBubble',
    name: 'Info Bubble',
    category: 'misc',
    path: (
      <>
        <path
          clipRule="evenodd"
          d="M9 17A8 8 0 109 1a8 8 0 000 16z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          clipRule="evenodd"
          d="M10.288 6.021c-.208.208-.46.313-.755.313s-.546-.105-.754-.313a1.028 1.028 0 01-.312-.754c0-.295.104-.546.312-.755.208-.208.46-.312.754-.312.295 0 .547.104.755.312.208.209.312.46.312.755s-.104.546-.312.754zm.312 5.594C9.907 13.072 9.144 13.8 8.313 13.8c-.282 0-.504-.097-.668-.292-.163-.194-.245-.46-.245-.797 0-.234.026-.454.078-.662l.83-3.358a.638.638 0 00.027-.188.357.357 0 00-.128-.289c-.085-.071-.232-.107-.44-.107h-.355l.155-.584 2.521-.123-1.196 4.811a2.294 2.294 0 00-.078.474c0 .078.013.137.039.178a.124.124 0 00.111.062c.108 0 .245-.093.412-.279.278-.307.551-.741.818-1.303l.406.272z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </>
    ),
    viewBox: '0 0 18 18',
  }
}

export const IconInfoBubble = makeIcon(def())
export default IconInfoBubble
